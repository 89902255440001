import React, { useState } from "react";
import LayoutWrapper from "../../component/LayoutWrapper/Index";
import CommunityTemplate from "../../component/CommunityTemplate";

const CommunityDetail = (props: any) => {
  const [siteTitle, setSiteTitle] = useState("");
  const [siteDesc, setSiteDesc] = useState("");
  const pageData = {
    id: "clairemont-homes-for-sale",
    titleTag: "Clairemont Homes for Sale | San Diego | The Selby Team",
    descTag:
      "Find homes for sale in Clairemont, known for its family-friendly atmosphere and central location in San Diego. Contact The Selby Team to learn more.",
  };
  return (
    <LayoutWrapper
      title={pageData.titleTag}
      desc={pageData.descTag}
      headerLogo="../../images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <CommunityTemplate
        id={pageData.id}
        setSiteTitle={setSiteTitle}
        setSiteDesc={setSiteDesc}
        listingId="40635"
      />
    </LayoutWrapper>
  );
};

export default CommunityDetail;
